f<template>
  <div>
    <BRow>
      <BCol lg="4">
        <Card
          :title="'Saldo Komerce'"
          :loading="loading.balance"
          :item="data.balance"
          :on-click="getMonit"
          :image-path="'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/rotate-left-1.svg'"
          :tooltip="'Saldo Komerce di Monit'"
          :source="'balances'"
        />
      </BCol>
    </BRow>
    <div class="mb-1 flex flex-row justify-between">
      <div class="text-black font-semibold text-xl">
        Performa Pertumbuhan User (Partner)
      </div>
      <div class="w-[120px]">
        <FlatPickr
          v-model="period"
          :config="configDate"
          class="custom-select"
        />
      </div>
    </div>
    <BRow>
      <BCol
        lg="4"
        md="4"
      >
        <Card
          :title="'Registrasi'"
          :item="data.register"
          :loading="loading.register"
          :image-path="'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user-add.svg'"
          :tooltip="'Jumlah partner yang Register selama satu bulan'"
          :source="'register'"
        />
      </BCol>
      <BCol
        lg="4"
        md="4"
      >
        <Card
          :title="'Total Kartu'"
          :item="data.card"
          :loading="loading.card"
          :image-path="'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card-add.svg'"
          :tooltip="'Total jumlah kartu yang dibuat oleh partner'"
          :source="'card'"
        />
      </BCol>
      <BCol
        lg="4"
        md="4"
      >
        <Card
          :title="'Pengeluaran'"
          :item="data.expense"
          :loading="loading.expense"
          :image-path="'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/money-send.svg'"
          :tooltip="'Jumlah pengeluaran seluruh partner selama satu bulan'"
          :source="'expense'"
        />
      </BCol>
    </BRow>
    <BRow>
      <BCol lg="8">
        <Chart
          :series="data.transaction"
          :loading="loading.transaction"
          :options="options"
        />
      </BCol>
      <BCol lg="4">
        <Table
          :loading="loading.items"
          :items="data.items"
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import { alertError } from '@toast'
import { today } from '@/store/helpers'
import FlatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import Card from './Card.vue'
import Chart from './Chart.vue'
import Table from './Table.vue'
import { options } from './config'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  components: {
    Card, Chart, Table, FlatPickr,
  },
  data() {
    return {
      period: today,
      monthlabel: LABELMONTH,
      IDR,
      alertError,
      options,
      data: {
        balance: {},
        register: {},
        card: {},
        expense: {},
        transaction: [],
        items: [],
      },
      loading: {
        balance: false,
        register: false,
        card: false,
        expense: false,
        transaction: false,
        items: false,
      },
      configDate: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        altFormat: 'j/n/Y',
        dateFormat: 'Y-m-d',
        maxDate: today,
        disableMobile: true,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y',
          }),
        ],
      },
    }
  },
  watch: {
    period: {
      handler() {
        this.getGrowthPartner()
        this.getGrowthCard()
        this.getGrowthExpanse()
        this.getPartnerExpense()
        this.getFlowTransaction()
      },
    },
  },
  mounted() {
    this.getMonit()
    this.getGrowthPartner()
    this.getGrowthCard()
    this.getGrowthExpanse()
    this.getPartnerExpense()
    this.getFlowTransaction()
  },
  methods: {
    async getMonit() {
      this.loading.balance = true
      const url = '/komcards/api/v1/admin/monit/update-balance'
      await newAxiosIns.post(url)
        .then(res => {
          const { data } = res.data
          this.data.balance = data
          this.loading.balance = false
        })
        .catch(() => {
          this.loading.balance = false
          this.alertError('Gagal load data monit')
        })
    },
    async getGrowthPartner() {
      this.loading.register = true
      const params = YEAR_MONTH(this.period)
      const url = `/auth/api/v1/admin/dashboard/user-register-growth?period=${params}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data.register = data
          this.loading.register = false
        })
        .catch(() => {
          this.loading.register = false
          this.alertError('Gagal load Register')
        })
    },
    async getGrowthCard() {
      this.loading.card = true
      const params = YEAR_MONTH(this.period)
      const url = `/komcards/api/v1/admin/dashboard/total-card-growth?period=${params}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data.card = data
          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.alertError('Gagal load data Total kartu')
        })
    },
    async getGrowthExpanse() {
      this.loading.expense = true
      const params = YEAR_MONTH(this.period)
      const url = `/komcards/api/v1/admin/dashboard/total-card-expense?period=${params}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data.expense = data
          this.loading.expense = false
        })
        .catch(() => {
          this.loading.expense = false
          this.alertError('Gagal load data Total kartu')
        })
    },
    async getFlowTransaction() {
      this.loading.transaction = true
      const params = YEAR_MONTH(this.period)
      const url = `/komcards/api/v1/dashboard/card-flow-transaction?period=${params}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data.transaction = [
            {
              name: 'Transaksi Masuk',
              data: data.map(item => item.credit_amount),
            },
            {
              name: 'Transaksi Keluar',
              data: data.map(item => -item.debit_amount),
            },
          ]
          this.loading.transaction = false
          this.options = {
            ...this.options,
            xaxis: {
              ...this.options.xaxis,
              categories: data.map(item => item.date),
            },
          }
        })
        .catch(() => {
          this.loading.transaction = false
          this.alertError('Gagal load data arus keuangan kartu')
        })
    },
    async getPartnerExpense() {
      this.loading.items = true
      const params = YEAR_MONTH(this.period)
      const url = `/komcards/api/v1/admin/dashboard/top-partner-expense?period=${params}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data.items = data
          this.loading.items = false
        })
        .catch(() => {
          this.loading.items = false
          this.alertError('Gagal load data Pengeluaran Kartu')
        })
    },
  },
}
</script>
<style>
.flatpickr-monthSelect-month.selected{
  background-color: #F95031 !important;
}
</style>
