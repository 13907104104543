import { IDR } from '@/libs/currency'
import {
  DATE, DAY_MONTH_YEAR, END_DATE_OF_MONTH, START_DATE_OF_MONTH,
} from '@/libs/filterDate'

const colorDefault = ['#34A770', '#E31A1A']
export const configColumn = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'name',
    label: 'Nama',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'count',
    label: 'Jumlah',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
]
export const options = {
  chart: {
    type: 'area',
    stacked: false,
    height: 350,
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  dataLabels: { enabled: false },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  yaxis: {
    forceNiceScale: true,
    labels: {
      style: {
        colors: '#000000',
      },
      offsetX: 0,
      formatter: val => `${IDR(val, 2, 2)}`,
    },
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 15,
    min: START_DATE_OF_MONTH,
    max: END_DATE_OF_MONTH,
    categories: [],
    labels: {
      formatter: value => DATE(value),
    },
  },
  grid: {
    yaxis: {
      lines: {
        offsetX: -30,
      },
    },
    padding: {
      left: 20,
    },
  },
  colors: colorDefault,
  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },
  noData: { text: 'Tidak Ada Data' },
  tooltip: {
    x: {
      show: true,
      formatter: value => DAY_MONTH_YEAR(value),
    },
    fixed: {
      enabled: false,
      position: 'topRight',
    },
    custom: ({ dataPointIndex, w }) => {
      let htmlRender = ''
      const arrayData = [...w.globals.series]
      arrayData.forEach((x, idx) => {
        if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
          htmlRender += ''
        } else {
          htmlRender += `<li class="my-0 mt-1" style="color: ${
            ['#34A770', '#E31A1A'][idx]
          };">
                <span class="text-black">${IDR(Math.abs(x[dataPointIndex], 2, 2) || 0, 2, 2)}</span> 
                </li>`
        }
      })
      return `<div class="d-grid px-1 rounded align-items-center">
              ${htmlRender}
              <br/>
            </div>`
    },
  },
}
