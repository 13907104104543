<template>
  <BCard>
    <div class="text-black font-semibold text-md">
      Arus Keuangan Kartu
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <ApexChart
        ref="chart"
        type="area"
        :options="options"
        :series="series"
      />
    </BOverlay>
  </BCard>
</template>

<script>
import ApexChart from 'vue-apexcharts'

export default {
  components: { ApexChart },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
