<template>
  <div>
    <BCard
      v-if="source === 'balances'"
      body-class="shadow-sm"
    >
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row items-center">
          <span class="text-black font-semibold">{{ title }}</span>
          <img
            v-b-tooltip.hover.top="tooltip"
            :src="icon"
            alt="Komerce"
            class="ml-[5px] cursor-pointer"
            width="20"
          >
        </div>
        <img
          class="bg-[#F95031] rounded-lg p-[5px] cursor-pointer"
          :src="imagePath"
          alt="Komerce"
          width="30"
          @click="onClick"
        >
      </div>
      <BSpinner
        v-if="loading"
        small
        class="ml-1"
        variant="primary"
      />
      <div
        v-else
        class="text-black text-2xl font-semibold ml-[5px] my-1"
      >
        {{ total }}
      </div>
    </BCard>
    <BCard
      v-else
      body-class="shadow-sm"
    >
      <div class="flex flex-row items-center">
        <img
          :src="imagePath"
          alt="Komerce"
          width="20"
        >
        <span class="text-black font-semibold text-md ml-[5px]">{{ title }}</span>
        <img
          v-b-tooltip.hover.top="tooltip"
          :src="icon"
          alt="Komerce"
          class="ml-[5px] cursor-pointer"
          width="20"
        >
      </div>
      <BSpinner
        v-if="loading"
        small
        class="ml-1"
        variant="primary"
      />
      <div v-else>
        <div class="text-black text-2xl font-semibold ml-[5px] my-1">
          {{ total }}
        </div>
        <div class="flex">
          <span class="text-black text-[25px] border-r pr-[5px]" />
          <div class="ml-1">
            <div class="text-[#34A270]">
              +  {{ cardDesc }}
            </div>
            <div class="text-[#AF6A13]">
              +  {{ cardSubDesc }}
            </div>
          </div>
        </div>
      </div>
    </BCard>
  </div>
</template>

<script>
import { IDR } from '@/libs/currency'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    onClick: {
      type: Function,
      default: () => null,
    },
    icon: {
      type: String,
      default:
        'https://storage.googleapis.com/komerce/assets/icons/info-circle.svg',
    },
    source: {
      type: String,
      default: '',
    },
    imagePath: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IDR,
    }
  },
  computed: {
    total() {
      if (this.source === 'register') {
        return this.item.total_user_monthly
      }
      if (this.source === 'card') {
        return this.item.total_card
      }
      if (this.source === 'expense') {
        return IDR(this.item.total_expense, 2, 2)
      }
      if (this.source === 'balances') {
        return IDR(this.item.balance, 2, 2)
      }
      return ''
    },
    cardDesc() {
      if (this.source === 'register') {
        return `${this.item.total_user_this_week} Minggu ini`
      }
      if (this.source === 'card') {
        return `${this.item.percentage_card_new_user}% User Baru (${this.item.total_card_new_user})`
      }
      if (this.source === 'expense') {
        return `${this.item.percentage_expense_new_user}% User Baru (${this.item.total_expense_new_user})`
      }
      return ''
    },
    cardSubDesc() {
      if (this.source === 'register') {
        return `${this.item.total_user_last_week} Minggu Kemarin`
      }
      if (this.source === 'card') {
        return `${this.item.percentage_card_existing_user}% User Lama (${this.item.total_card_existing_user})`
      }
      if (this.source === 'expense') {
        return `${this.item.percentage_expense_existing_user}% User Lama (${this.item.total_expense_existing_user})`
      }
      return ''
    },
  },
}
</script>
